<template>
    <div class="scrlg_dtl_section">
        <div class="row">
            <div class="col-xl-8">
                <div>
                    <div v-if="matches != ''">
                        <div>
                            <div class="ft_lc_list vevent" v-for="(date, index) in matches" :key="date.id">
                                <h2 class="header-cg-48378"><a href="javascript:;">{{index|
                                    formatDate2('MM YYYY')}}</a></h2>
                                <div class="ft_lc_row" :key="match.id" v-for="match in date">
                                    <div class="ft_lc_time">
                                        <span class="ft_lc_time_upcmng" v-if="match.status == 'Result Only'">
                                                R.O
                                        </span>
                                        <span class="ft_lc_time_upcmng" v-if="match.status == 'NSY'">
                                            NSY
                                        </span>
                                        <span class="ft_lc_time_live"
                                              v-if="((match.status == '1st Half' || match.status == 'Half Time' || match.status == '2nd Half'|| match.status=='ET 1st Half' || match.status=='ET 2nd Half' || match.status=='Penalty' || match.status=='inprogress') && (match.currentPeriod !='Penalty'))">
                                            {{match.minutes}}'
                                        </span>
                                        <span class="ft_lc_time_live"
                                              v-if="(match.status=='inprogress' && match.currentPeriod =='Penalty')">
                                            PEN
                                        </span>
                                        <span class="ft_lc_time_ft"
                                              v-if="(match.status == 'Finished' && match.minutes != 'Finished AP')">
                                            FT
                                        </span>
                                        <span class="ft_lc_time_ft"
                                              v-if="(match.status == 'Finished' && match.minutes == 'Finished AP')">
                                            Fi. AP
                                        </span>
                                        <span class="ft_lc_time_ft" v-if="match.status == 'Finished AET'">
                                            AET
                                        </span>
                                    </div>
                                    <div class="ft_lc_rslt">
                                        <router-link :to="{ name: 'rugby_match_detail.overview', params: { slug: $slugify(match.tournament.name + ' ' + $getSafe(()=>$filterTeam(match.rugbyMatchCompetitors, true).team.name) + ' vs ' + $getSafe(()=>$filterTeam(match.rugbyMatchCompetitors, false).team.name)), date: match.date, matchId: match.id } }">
                                            <div class="ft_lc_name">
                                                    <span class="ft_lc_hm_tname">{{match.rugbyMatchCompetitors[0].is_home==true?match.rugbyMatchCompetitors[0].team.name:match.rugbyMatchCompetitors[1].team.name}}</span>
                                                    <span class="ft_lc_hm_tname_mbl">{{match.rugbyMatchCompetitors[0].is_home==true?match.rugbyMatchCompetitors[0].team.name:match.rugbyMatchCompetitors[1].team.name}}</span>
                                                    <img :onerror="$teamDefaultLogo()" :src="$storageURL+'images/rugby/'+$getSafe(()=>$filterTeam(match.rugbyMatchCompetitors, true)).team.id+'_90x70.webp'" :alt="$getSafe(()=>$filterTeam(match.competitors, true).team.name)+' Team Logo'" class="ft_lc_hm_pic">
                                            </div>
                                            <div class="ft_lc_score">
                                                <h6 class="ft_lc_score_ft"
                                                    v-if="match.status == 'Result Only' || match.status == 'NSY' || match.status == 'Abandoned'|| match.status == 'No Info Yet'|| match.status == 'Cancelled'|| match.status == 'Postponed'|| match.status == 'Awarded' || match.status == 'Delayed'">
                                                    {{match.datetime| formatDate('HH:mm')}}</h6>
                                                <h6 class="ft_lc_score_ft"
                                                    v-if="match.status == '1st Half' || match.status == 'Half Time' || match.status == '2nd Half' || match.status == 'inprogress'">
                                                    {{match.cfs}}</h6>
                                                <h6 class="ft_lc_score_ft"
                                                    v-if="match.status == 'Finished' || match.status == 'Full Time'">
                                                    {{match.ft}}</h6>
                                                <h6 class="ft_lc_score_ft"
                                                    v-if="match.status == 'Finished AP'  || match.status == 'Finished AET'">
                                                    {{match.et}}</h6>
                                                <h6 class="ft_lc_score_ft"
                                                    v-if="match.status == 'ET 1st Half' || match.status == 'ET 2nd Half' || match.status == 'Penalty' || match.status == 'inprogress'">
                                                    {{match.et}}</h6>
                                            </div>
                                            <div class="ft_lc_name">
                                                    <img :onerror="$teamDefaultLogo()" :src="$storageURL+'images/rugby/'+$getSafe(()=>$filterTeam(match.rugbyMatchCompetitors, false)).team.id+'_90x70.webp'" :alt="$getSafe(()=>$filterTeam(match.competitors, false).team.name)+' Team Logo'" class="ft_lc_awy_pic">
                                                    <span class="ft_lc_awy_tname">{{match.rugbyMatchCompetitors[0].is_home==false?match.rugbyMatchCompetitors[0].team.name:match.rugbyMatchCompetitors[1].team.name}}</span>
                                                    <span class="ft_lc_awy_tname_mbl">{{match.rugbyMatchCompetitors[0].is_home==false?match.rugbyMatchCompetitors[0].team.name:match.rugbyMatchCompetitors[1].team.name}}</span>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="competitions_cards_score">
                        </div>
                    </div>
                    <div v-else>
                        <p style="text-align: center;font-size: 20px;font-weight: 500;">No Fixtures available!</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-4">
                <div class="sabc_ftbllist_league_table" v-for="tournament_table in tables" :key="tournament_table.id">
                    <h2>{{tournament_table.name}}</h2>
                    <div class="sabc_ftbllist_league_block">
                        <table class="sabc_ftbllist_league_tableinr">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{{ $t('Team') }}</th>
                                <th>P</th>
                                <th class="current_league_mobile_hidden">GD</th>
                                <th>{{ $t('Points') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr :key="table.id" v-for="table in tournament_table.rugbyTournamentTable">
                                <td >
                                    {{table.place}}
                                </td>
                                <td>
                                    <div class="current_league_score_table_teamlogo">
                                        <img :src="$storageURL+'images/rugby/'+table.team.id+'_50x40.webp'" alt="team logo">
                                        {{table.team.name}}
                                    </div>
                                </td>
                                <td>
                                    {{table.played}}
                                </td>
                                <td>
                                    {{table.difference}}
                                </td>
                                <td>
                                    {{table.points}}
                                </td>
                            </tr>
                            <tr v-for="league in tournament_table.leagues" :key="league.id">
                                <td colspan="11">
                                    <div class="league_color_info">
                                        <span :style="{'background': league.color}"></span>
                                        {{league.name}}
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <!--                        <div class="current_league_score_btn">-->
                        <!--                            <a @click="sliceCounter=tables.length" href="javascript:;" v-if="sliceCounter == 4">{{ $t('football.match-detail.Show Full')}}</a>-->
                        <!--                            <a @click="sliceCounter=4" href="javascript:;" v-if="sliceCounter != 4">{{ $t('football.match-detail.Show Less')}}</a>-->
                        <!--                        </div>-->
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import Table from '../match-detail/table.vue';

    import LeagueListing from './league-listing';

    export default {
        name: 'RugbyLeagueDetail',
        data() {
            return {
                tables: [],
                matches: ''

            }
        },
        jsonld() {
            if (isServer)
                return {
                    "@context": "http://schema.org",
                    "@graph": []
                }

            if (!this.matches)
                return

            let events = [];
            var matchesList = this.matches;
            console.log(matchesList);
            if (typeof matchesList === 'object' && matchesList !== null) {
                matchesList = Object.values(matchesList);
            }
            var _index=0;
            matchesList.forEach((tMatch) => {
                tMatch.forEach((match) => {
                    _index++;
                    
                    // var tournamentName = match.tournament.name;
                    var venue = match.tournament.name;
                    var _team1 = match.rugbyMatchCompetitors[0].team.name;
                    var _team2 = match.rugbyMatchCompetitors[1].team.name;
                    var url = this.$router.resolve({ name: 'rugby_match_detail.overview', params: { slug: this.$slugify(match.tournament.name + ' ' + this.$getSafe(()=>this.$filterTeam(match.rugbyMatchCompetitors, true).team.name) + ' vs ' + this.$getSafe(()=>this.$filterTeam(match.rugbyMatchCompetitors, false).team.name)), date: match.date, matchId: match.id } });
                    
                    if(_index < 21){
                        events.push(
                            {
                                "@context":"https://schema.org",
                                "@type": "Event",
                                "name": _team1+' VS '+_team2,
                                "startDate": this.convertDateTime(match.datetime),
                                "endDate": this.convertDateTime(match.datetime),
                                "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
                                "eventStatus": "EventScheduled",
                                "location": {
                                    "@type": "Place",
                                    "name": venue,
                                    "address": {
                                        "@type": "PostalAddress",
                                        "streetAddress": "cnr Henley and Artilery Road, Auckland Park",
                                        "addressLocality": "Private Bag X1, AUCKLAND PARK",
                                        "addressRegion": "Johannesburg",
                                        "postalCode": "2006",
                                        "addressCountry": "South Africa"
                                    }
                                },
                                "image": [
                                    "https://cms.sabcsport.com/storage/images/rugby/"+match.rugbyMatchCompetitors[0].team.id+".webp",
                                    "https://cms.sabcsport.com/storage/images/rugby/"+match.rugbyMatchCompetitors[1].team.id+".webp"
                                ],
                                "description" : "Join us for the exciting match between "+_team1+" and "+_team2,
                                "url": "https://www.sabcsport.com"+url.href,
                                "offers": {
                                    "@type": "Offer",
                                    "name": _team1+' VS '+_team2,
                                    "url": "https://www.sabcsport.com"+url.href,
                                    "priceCurrency": "EUR",
                                    "validFrom": this.convertDateTime(match.datetime),
                                    "availability": "https://schema.org/InStock",
                                    "price": "0"
                                },
                                "performer": [
                                    {
                                        "@type": "SportsTeam",
                                        "name": _team1
                                    },
                                    {
                                        "@type": "SportsTeam",
                                        "name": _team2
                                    }
                                ],
                                "organizer": {
                                    "@type": "Organization",
                                    "name": "SABCSport",
                                    "url": "https://www.sabcsport.com"
                                }
                            }
                        )
                    }
                });
            });
            return { events };
        },
        components: {
            LeagueListing,
            Table
        },
        mounted() {
            this.getLeagueDetail();
        },
        serverPrefetch() {
            return this.getLeagueDetail();

        },
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            if (this.tournament_detail) {
                var replacements = {
                    '%TOURNAMENT_NAME%': this.tournament_detail.name,
                };

                let title = this.$getSafe(() => this.$replacePlaceHolders(ss.rugby_league_detail_meta_title,replacements));
                let description = this.$getSafe(() => this.$replacePlaceHolders(ss.rugby_league_detail_meta_description,replacements));
                let keywords = this.$getSafe(() => ss.rugby_league_detail_meta_keywords);

                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
                };
            }
        },
        watch: {
            $route(to, from) {
                this.getLeagueDetail();
            }
        },
        methods: {
            getLeagueDetail() {
                this.url = this.$RUGBY_GET_LEAGUE_DETAIL + '?tournamentId=' + this.$route.params.leagueId + '&match_type=' + this.$route.params.matchType;
                return this.$axios.get(this.url)
                    .then(response => {
                        this.matches = response.data.matches;
                        this.tables = response.data.table;
                    })
                    .catch(error => console.log(error))
            },
            convertDateTime(input, addFlag = false) {
                // Replace '024' with '2024' for a valid year
                const year = input.substring(0, 4) === '024' ? '2024' : input.substring(0, 4);
                const datePart = `${year}${input.substring(4)}`; // '2024-10-27 18:30:00'

                // Create a Date object
                const date = new Date(datePart.replace(' ', 'T'));
                if(addFlag){
                    date.setHours(date.getHours() + 2);
                }

                // Format the date as ISO 8601
                const isoString = date.toISOString(); // 'YYYY-MM-DDTHH:MM:SS.sssZ'

                // Remove milliseconds and add Z for UTC
                return isoString.slice(0, 19) + 'Z';
            }
        }
    }
</script>
